<template>
  <div class="page-connection d-flex justify-content-center align-items-center overflow-scroll">

    <background-vegetable/>

    <b-row class="justify-content-center align-items-stretch p-0 m-0 w-100 mh-100">
      <b-col class="d-none d-md-flex col-md-3 col-lg-3 col-xl-3 align-items-center bg-light">
        <img alt="login" class="mx-auto" src="@/assets/images/pages/vegetable_logos.svg">
      </b-col>
      <b-col class="col-12 col-sm-7 col-md-6 col-lg-4 col-xl-4 bg-white">
        <h1 class="text-primary font-large-5 font-weight-bolder col-12 text-center p-0 pt-1 m-0"
            style="font-family: Hortypo, serif">
          Hortilio
        </h1>
        <p class="text-center text-secondary">{{ text }}</p>
        <slot/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCol, BRow} from "bootstrap-vue"
import BackgroundVegetable from "@/components/global/BackgroundVegetable";

export default {
  name: "JayaConnectionLayout",

  components: {
    BackgroundVegetable,
    BCol,
    BRow,
  },

  props: {
    text: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped>
.page-connection {
  width: 100vw;
  height: 100vh;
  background-color: var(--dark);
}
</style>